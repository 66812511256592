const reports = {
  users: {
    table: {
      users: 'Usuario',
      login: 'Inicio de sesión',
      accessLevel: 'Nivel de acceso',
      activationDate: 'Fecha de activación',
      lastLogin: 'Último inicio de sesión',
      status: 'Estado',
    },
    search: 'Usuario / Inicio de sesión',
    refreshButton: 'Buscar',
    resetButton: 'Reiniciar',
    exportButton: 'Exportar',
    dateFilter: {
      activation: 'Fecha de activación',
      lastLogin: 'Último inicio de sesión',
      notLogged: 'No ha iniciado sesión en los últimos',
    },
    statusFilter: {
      all: 'Todos los estados',
      active: 'Activo',
      inactive: 'Inactivo',
    },
    allAccesslevel: 'Todos los niveles de acceso',
  },
  cards: {
    table: {
      card: 'Credencial',
      format: 'Formato',
      username: 'Nombre de usuario',
      activationDate: 'Fecha de activación',
      lastUsed: 'Último uso',
      status: 'Estado',
      assigned: 'Asignado',
      stolen: 'Robado',
      lost: 'Perdido',
      lockdown: 'Bloqueo',
      usedForAccess: 'Usado para acceso',
    },
    cardSearch: 'Credencial',
    usernameSearch: 'Usuario',
    cardStatusRadio: 'Estatus de credencial',
    cardOptionsRadio: 'Opciones de credencial',
    allFormats: 'Todos los formatos',
    cardStatusFilter: {
      all: 'Todo',
      active: 'Activado',
      inactive: 'Inactivado',
    },
    cardAssignedFilter: {
      all: 'Todo',
      yes: 'Si',
      no: 'No',
    },
    dateFilter: {
      activation: 'Fecha de activación',
      lastUsed: 'Último uso',
      notUsed: 'No utilizado en',
    },
  },
  events: {
    table: {
      date_created: 'Fecha de creacion',
      controller: 'Controlador',
      event_class: 'Clase de evento',
      object_type: 'Tipo de objeto',
      event_type: 'Tipo de evento',
      description: 'Descripción',
      access: 'Accesso',
    },
    report: 'Informe',
    all_users: 'Todos los usuarios',
    search: 'Búsqueda general',
    all: 'Todo',
    access: 'Acceso',
    security: 'Seguridad',
    alarms: 'Alarmas',
    troubles: 'Nubes',
    lockdown: 'Aislamiento',
    others: 'Otros',
    cloud: 'Datos de actividad',
    remove_report_msg: '¿Está seguro de que desea eliminar el informe?',
    save_report: 'Guardar reporte',
    save_report_msg: 'Complete el nombre del informe y guárdelo',
    name_report: 'Nombre - Informe',
    event_class: 'Clase de evento',
    event_type: 'Tipo de evento',
    object: 'Objeto',
    object_type: 'Tipo de objeto',
    print_all_description: 'Imprimir toda la descripción',
    date: 'Fecha',
    from: 'De',
    to: 'A',
  },
};

export default reports;
